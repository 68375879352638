module.exports = {
  CLIENT_ID: 'l7xxece0943cdb214fe4b3b0cbcfbda4b0a1',
  API_ROOT: 'https://dv9-api.autozone.com',
  AUTH_ROOT: 'https://auth-st.autozone.com',
  AUTH_ROOT_MOBILE: 'https://dv-cdn-static.autozonepro.com/login/index.html',
  AUTH_SCOPE: 'openid%20profile%20azjobinfo%20az_mobile_sso',
  REDIRECT_URI: 'http://dev.autozone.com:3000',
  ENV: 'dv-ext',
  WHISPERS_URL: 'https://dv9-api.autozone.com',
  PKCE_LOGIN_URL: 'https://dv9-api.autozone.com/auth/oauth/v2/pkcelogin',
  SSO_DEVICES: ['CT40'],
  INACTIVITY_TIMEOUT: 10,
  FETCH_TIMEOUT: 10000,
  INACTIVITY_TIMEOUT_FOR_TOASTS: 1000 * 60 * 2, // 2 mins timeout
};
